/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

export default {
  namespaced: true,
  state: {
    messages: []
  },

  //mutations are the recommended way to change the state in a vue app
  mutations: {
    pushNewMessage(state, newValue) {
      state.messages.push(newValue);
    },
    setMessages(state, list) {
      state.messages = list || [];
    },
    clearMessages(state) {
      state.messages = [];
    }
  },

  //actions are used to communicate with remote apis and fetch data async
  actions: {
    push({commit}, errorData) {
      if (errorData.text && typeof errorData.text === 'string' && errorData.text.trim() !== '') {
        commit('pushNewMessage', errorData);
      }
    },
    set({commit}, list) {
      commit('setMessages', list);
    },
    clear({commit}) {
      commit("clearMessages");
    }
  },

  //getters are helpers to retrieve data from the state in a vue app
  getters: {
    list(state) {
      return (state.messages.length > 0) ? state.messages : null;
    },
    print(state) {
      if ((state.messages.length > 0)) {
        let messages = [];
        state.messages.forEach((oneMessage) => {
          let myMessage = '';

          if (oneMessage.code) {
            myMessage += oneMessage.code + ": ";
          }
          if (oneMessage.error) {
            myMessage += `Error:${oneMessage.error}`;
          }
          if (oneMessage.data) {
            myMessage += oneMessage.data;
          }

          messages.push(myMessage);
        });

        return messages;
      } else {
        return null;
      }
    }
  }
}
