/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

const clone = (obj) => JSON.parse(JSON.stringify(obj));

export default {
  namespaced: true,

  state: {
    loaded: false,
    token: null,
    user: null
  },

  mutations: {

    //set or clear user
    setUser(state, value) {
      if (value && typeof value === 'object') {
        state.user = clone(value.user);
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    },

    clearUser(state) {
      state.user = null;
      localStorage.removeItem('user');
    },
    
    //set or clear tokens
    setToken(state, value) {
      if (value) {
        if (typeof value !== 'object') {
          console.error(`Could not save invalid token object: ${JSON.stringify(value)}`);
          return;
        }
        state.token = clone(value);
        localStorage.setItem('token', JSON.stringify(state.token));
      } else {
        state.token = null;
        localStorage.removeItem('token');
      }
    },

    reloadState(state) {
      state.token = JSON.parse(localStorage.getItem('token'));
      state.user = JSON.parse(localStorage.getItem('user'));
      state.loaded = true;
    },
  },

  actions: {
    setUser({commit}, value) {
        commit('setUser', value);
    },
  
    setToken({commit}, value) {
      commit('setToken', value);
    },

    clear({commit}) {
      commit('setToken', null);
      commit('clearUser', null);
    },

    reloadState({commit, state}, force = false) {
      if (!state.loaded || force) commit('reloadState');
    },
  },

  getters: {
    getToken: state => state.token,
    getUser: state => state.user
  }
}
