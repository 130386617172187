import Vue from 'vue';

import '@/styles/overrides.sass'
import '@/styles/_text.scss'

import Vuetify from 'vuetify/lib/framework';
import {
	VApp,
	VAppBar,
	VAppBarNavIcon,
	VAlert,
	VAutocomplete,
	VAvatar,
	VBadge,
	VBanner,
	VBottomNavigation,
	VBottomSheet,
	VBreadcrumbs,
	VBtn,
	VBtnToggle,
	VCalendar,
	VCard,
	VCarousel,
	VCheckbox,
	VChip,
	VChipGroup,
	VColorPicker,
	VContent,
	VCombobox,
	VCounter,
	VData,
	VDataIterator,
	VDataTable,
	VDatePicker,
	VDialog,
	VDivider,
	VExpansionPanel,
	VFileInput,
	VFooter,
	VForm,
	VGrid,
	VHover,
	VIcon,
	VImg,
	VInput,
	VItem,
	VItemGroup,
	VLabel,
	VLazy,
	VList,
	VListGroup,
	VListItem,
	VListItemAction,
	VMain,
	VMenu,
	VCardTitle,
	VCardText,
	VCardSubtitle,
	VCardActions,
	VSpacer,
	VToolbarTitle,
	VMessages,
	VNavigationDrawer,
	VOtpInput,
	VOverflowBtn,
	VOverlay,
	VPagination,
	VSheet,
	VParallax,
	VPicker,
	VProgressCircular,
	VProgressLinear,
	VRadioGroup,
	VRangeSlider,
	VRating,
	VResponsive,
	VSelect,
	VSkeletonLoader,
	VSlider,
	VSlideGroup,
	VSnackbar,
	VSparkline,
	VSpeedDial,
	VStepper,
	VSubheader,
	VSwitch,
	VSystemBar,
	VTabs,
	VTab,
	VTabItem,
	VTabsItems,
	VTabsSlider,
	VTextarea,
	VTextField,
	VThemeProvider,
	VTimeline,
	VTimePicker,
	VToolbar,
	VTooltip,
	VTreeview,
	VVirtualScroll,
	VWindow,
	VRow,
	VCol,
	VContainer,
} from 'vuetify/lib/index';

Vue.use(Vuetify, {
	components: {
		VApp,
		VAppBar,
		VAppBarNavIcon,
		VAlert,
		VAutocomplete,
		VAvatar,
		VBadge,
		VBanner,
		VBottomNavigation,
		VBottomSheet,
		VBreadcrumbs,
		VBtn,
		VBtnToggle,
		VCalendar,
		VCard,
		VCarousel,
		VCheckbox,
		VChip,
		VChipGroup,
		VColorPicker,
		VContent,
		VCombobox,
		VCounter,
		VData,
		VDataIterator,
		VDataTable,
		VDatePicker,
		VDialog,
		VDivider,
		VExpansionPanel,
		VFileInput,
		VFooter,
		VForm,
		VGrid,
		VHover,
		VIcon,
		VImg,
		VInput,
		VItem,
		VItemGroup,
		VLabel,
		VLazy,
		VList,
		VListGroup,
		VListItem,
		VListItemAction,
		VMain,
		VMenu,
		VCardTitle,
		VCardText,
		VCardSubtitle,
		VCardActions,
		VSpacer,
		VToolbarTitle,
		VMessages,
		VNavigationDrawer,
		VOtpInput,
		VOverflowBtn,
		VOverlay,
		VPagination,
		VSheet,
		VParallax,
		VPicker,
		VProgressCircular,
		VProgressLinear,
		VRadioGroup,
		VRangeSlider,
		VRating,
		VResponsive,
		VSelect,
		VSkeletonLoader,
		VSlider,
		VSlideGroup,
		VSnackbar,
		VSparkline,
		VSpeedDial,
		VStepper,
		VSubheader,
		VSwitch,
		VSystemBar,
		VTabs,
		VTab,
		VTabItem,
		VTabsItems,
		VTabsSlider,
		VTextarea,
		VTextField,
		VThemeProvider,
		VTimeline,
		VTimePicker,
		VToolbar,
		VTooltip,
		VTreeview,
		VVirtualScroll,
		VWindow,
		VRow,
		VCol,
		VContainer,
	}
});

const theme = {
	primary: '#00746b', // '#e91e63'
	secondary: '#1867c0',
	accent: '#9C27b0',
	info: '#00CAE3',
	muted: '#6c757d',
	'spacer': 4,
	'spacers-steps': 32,
	'spacer-multiplier': 1.6
}

export default new Vuetify({
   theme: {
       themes: {
           dark: theme,
           light: theme,
       },
   },
});
