/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

export default {
	namespaced: true,
	state: {
		tabs: {}
	},
	
	//mutations are the recommended way to change the state in a vue app
	mutations: {
		set( state, context ) {
			state.tabs[ context[ 0 ] ] = context[ 1 ];
		},
		setFull( state, context ) {
			if(context && context.tabs){
				state.tabs = context.tabs;
			}
		},
		clear( state ) {
			state.tabs = null;
		}
	},
	
	//actions are used to communicate with remote apis and fetch data async
	actions: {
		set( { commit }, context ) {
			commit( 'set', context );
		},
		reload( { commit }, context ) {
			commit( 'setFull', context );
		},
		clear( { commit } ) {
			commit( "clear" );
		}
	},
	
	//getters are helpers to retrieve data from the state in a vue app
	getters: {
		get: ( state ) => ( name ) => {
			return state.tabs[ name ];
		},
		list( state ) {
			return state;
		}
	}
}
