import Vue from 'vue'
import Vuex from 'vuex'

import keyModule         from './modules/key';
import authModule        from './modules/auth';
import messageModule     from './modules/messages';
import tabsModule        from './modules/tabs';
import imageCacherModule from './modules/imageCacher';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    // called at boot to reload state from localstorage
    init(store, force = false) {
      try {
        store.dispatch('auth/reloadState', force);
      } catch (e) {
        console.error(e);
        store.dispatch('clear');
      }
    },
  },
  modules: {
    key: keyModule,
    auth: authModule,
    globalMessages: messageModule,
    imC: imageCacherModule,
    tabs: tabsModule
  }
})
